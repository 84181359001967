import BuildInformation from '@aurora/shared-client/components/common/BuildInformation/BuildInformation';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import useCommunityCookieProperties from '@aurora/shared-client/components/community/useCommunityCookieProperties';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { PageAndEmptyParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { canUseDOM } from 'exenv';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { WidgetEditLevel } from '../../../helpers/quilt/PageEditorHelper';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC, WidgetProps } from '../../common/Widget/types';
import PageEditorQuiltWrapperContext from '../../context/PageEditorQuiltWrapperContext/PageEditorQuiltWrapperContext';
import useTranslation from '../../useTranslation';
import localStyles from './FooterWidget.module.pcss';
import useCommunityAccess from '../../authentication/AuthenticationLink/useCommunityAccess';
import { focusFirstTabbableChildElement } from '@aurora/shared-client/helpers/ui/KeyboardNavigationHelper/KeyboardNavigationHelper';

const KhorosLogo = dynamic(() => import('../KhorosLogo/KhorosLogo'));
const EditableFooterWidget = dynamic(() => import('./EditableFooterWidget'), {
  ssr: false
});

interface Props extends WidgetProps {
  /**
   * Whether to show the Home Link and the navigation for the top page.
   */
  useHomeAndTopLink?: boolean;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}
function scrollToTop(): void {
  if (canUseDOM) {
    window.scrollTo(0, 0);
    focusFirstTabbableChildElement(document.body);
  }
}
/**
 * Footer Widget with Khoros Logo
 *
 * @author Adam Ayres
 */
const FooterWidget: WidgetFC<Props> = props => {
  const { canAccessCommunity } = useCommunityAccess();
  const {
    publicConfig: { quiltsV2Enabled, poweredByLogoEnabled }
  } = useContext(TenantContext);
  const { showEditControls } = useContext(PageEditorQuiltWrapperContext);
  const finalProps = { useHomeAndTopLink: true, ...props };
  finalProps.editLevel = WidgetEditLevel.LOCKED;
  const { useHomeAndTopLink, className } = finalProps;
  const { Link } = useEndUserRoutes();
  const cx = useClassNameMapper(localStyles);
  const { data, loading } = useCommunityCookieProperties(module);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.FOOTER_WIDGET);

  if (textLoading || loading) {
    return null;
  }

  const { cookiePrivacyPolicyUrl } = data?.community?.cookieProperties ?? {};

  if (quiltsV2Enabled && showEditControls) {
    return <EditableFooterWidget useHomeAndTopLink={useHomeAndTopLink} className={className} />;
  }

  return (
    <EditableWidget props={finalProps}>
      <footer className={cx('lia-footer', className)}>
        <ThemedContainer
          data-testid="FooterWidget.Link"
          className={cx('lia-container')}
          ignoreXsGutters
        >
          <div className={cx('lia-footer-links')}>
            {useHomeAndTopLink && (
              <>
                <Link<PageAndEmptyParams>
                  route={EndUserPages.CommunityPage}
                  passHref
                  legacyBehavior={true}
                >
                  <a className={cx('lia-link')}>{formatMessage('homeLink')}</a>
                </Link>
                <Button
                  data-testid="FooterWidget.Button"
                  onClick={scrollToTop}
                  variant={ButtonVariant.LINK}
                  className={cx('lia-link')}
                >
                  {formatMessage('topOfPage')}
                </Button>
              </>
            )}
            {cookiePrivacyPolicyUrl && (
              <a
                className={cx('lia-link')}
                href={cookiePrivacyPolicyUrl}
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage('privacyPolicy')}
              </a>
            )}
            {canAccessCommunity && (
              <Link<PageAndEmptyParams>
                route={EndUserPages.HelpFAQPage}
                passHref
                legacyBehavior={true}
              >
                <a className={cx('lia-link')}>{formatMessage('helpLink')}</a>
              </Link>
            )}
          </div>
          <BuildInformation />
          {poweredByLogoEnabled && (
            <KhorosLogo className={cx('lia-logo-wrap')} svgClassName={cx('lia-logo')} />
          )}
        </ThemedContainer>
      </footer>
    </EditableWidget>
  );
};
export default FooterWidget;
