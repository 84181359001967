import getConfig from 'next/config';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import useDateTime from '../../useDateTime';
import useTranslation from '../../useTranslation';
import ExternalLink from '../ExternalLink/ExternalLink';
import localStyles from './BuildInformation.module.pcss';

/**
 * Display build information.
 *
 * @constructor
 *
 * @author Dolan Halbrook, Willi Hyde
 */
const BuildInformation: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { publicRuntimeConfig } = getConfig() ?? {};

  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    loading: textLoading,
    FormattedMessage
  } = useTranslation(SharedComponent.BUILD_INFORMATION);
  const { intl } = useDateTime();

  if (!publicRuntimeConfig?.buildInformationVisible) {
    return null;
  }

  const buildHash = process.env.NEXT_PUBLIC_BUILD_HASH;
  const buildNumber = process.env.NEXT_PUBLIC_BUILD_NUMBER;
  const buildTime = process.env.NEXT_PUBLIC_BUILD_TIME;
  const buildUrl = process.env.NEXT_PUBLIC_BUILD_URL;
  const hasInfo = buildHash || buildNumber || buildTime;

  if (textLoading || !hasInfo) {
    return null;
  }

  function getFormattedBuildTime(): string {
    return !Number.isNaN(buildTime)
      ? intl.formatDate(Number(buildTime), {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        })
      : buildTime;
  }

  function renderBuildUrl(chunks: string): React.ReactNode {
    return buildUrl ? (
      <ExternalLink className={cx('lia-build-link')} href={buildUrl}>
        {chunks}
      </ExternalLink>
    ) : (
      chunks
    );
  }

  return (
    <section className={cx('lia-build-wrap')}>
      {buildNumber && (
        <span>
          <FormattedMessage
            id="buildNumber"
            values={{
              a: renderBuildUrl,
              buildNumber
            }}
          />
        </span>
      )}
      {buildHash && <span>{formatMessage('buildHash', { buildHash })}</span>}
      {buildTime && (
        <span suppressHydrationWarning={true}>
          {formatMessage('buildTime', { buildTime: getFormattedBuildTime() })}
        </span>
      )}
    </section>
  );
};

export default BuildInformation;
