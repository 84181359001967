import type { QueryHookOptions } from '@apollo/client/react/types/types';
import type {
  CommunityCookiePropertiesQuery,
  CommunityCookiePropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import communityCookiePropertiesQuery from './CommunityCookieProperties.query.graphql';
import type { ExtendedQueryResult } from '../useQueryWithTracing';
import useQueryWithTracing from '../useQueryWithTracing';

/**
 * Gets cookie properties (read-only settings) for the current community.
 *
 * @param module The module
 * @param options Optional options to pass to the query
 * @author Agustin Sosa
 */
export default function useCommunityCookieProperties(
  module: NodeModule | string,
  options?: QueryHookOptions<
    CommunityCookiePropertiesQuery,
    CommunityCookiePropertiesQueryVariables
  >
): ExtendedQueryResult<CommunityCookiePropertiesQuery, CommunityCookiePropertiesQueryVariables> {
  return useQueryWithTracing<
    CommunityCookiePropertiesQuery,
    CommunityCookiePropertiesQueryVariables
  >(module, communityCookiePropertiesQuery, options);
}
